import React, { useEffect, useState } from 'react';
import './Blog.scss';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import IndexLayout from '../../layouts';
import Container from '../../components/Container';
import BlogTags from './BlogTags';
import TopSection from './TopSection';
import Posts from './Posts';
import FeaturedArticle from './FeaturedArticle';
import SearchInput from '../../components/SearchInput';
import NoPostsFound from './NoPostsFound';
import { BlogPost } from '../../types/BlogPost';
import { BlogPostTag } from '../../types/BlogPostTag';
import strapiClient from '../../api/strapiClient';

interface BlogProps {
  posts: BlogPost[];
  tags: BlogPostTag[];
  isPreview?: boolean;
}

const Blog: React.FC<BlogProps> = (props) => {
  const { posts = [], tags = [], isPreview = false } = props;
  const [activeTag, setActiveTag] = useState('');
  const [searchString, setSearchString] = useState('');

  // results should match tag && search string
  const filteredPosts = React.useMemo(
    () =>
      posts?.filter((p: BlogPost) => {
        const searchStr = searchString.toLowerCase();
        if (activeTag && !p.attributes.tags.data?.some((t: BlogPostTag) => t.attributes.slug === activeTag)) {
          return false;
        }

        return p.attributes.title.toLowerCase().includes(searchStr) || p.attributes.description.toLowerCase().includes(searchStr);
      }),
    [posts, tags, activeTag, searchString]
  );

  return (
    <IndexLayout
      pageName="Blog"
      helmet={{
        title: 'TileDB Blog',
        description:
          'Data management insights, product updates, and thought leadership on the modern data stack, single-cell analysis, generative AI, and more.',
      }}
    >
      <Header variant="transparent" />
      <main className="main">
        <TopSection />
        {filteredPosts.length > 0 && <FeaturedArticle post={posts[0]} isPreview={isPreview} />}
        <Container>
          <div className="TDB-Blog__bar">
            <BlogTags onChange={setActiveTag} activeTag={activeTag} tags={tags} />
            <SearchInput value={searchString} onChange={setSearchString} />
          </div>
        </Container>
        {!filteredPosts.length && <NoPostsFound searchString={searchString} />}
        <Posts posts={filteredPosts} isPreview={isPreview} />
      </main>
      <Footer />
    </IndexLayout>
  );
};

const ServerSideBlog = (props: any) => {
  const { pageContext } = props;

  return <Blog posts={pageContext.allPosts} tags={pageContext.allTags} />;
};

export const ClientSideBlog = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [tags, setTags] = useState<BlogPostTag[]>([]);

  const getPosts = async () => {
    const result: any = await strapiClient.getPublishedAndDraftPosts();

    setPosts(result.data.data);
  };

  const getTags = async () => {
    const result: any = await strapiClient.getPublishedTags();

    setTags(result.data.data);
  };

  useEffect(() => {
    getPosts();
    getTags();
  }, []);

  if (!posts.length) {
    return <div>Loading...</div>;
  }

  return <Blog posts={posts} tags={tags} isPreview />;
};

export default ServerSideBlog;
